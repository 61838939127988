import React, { Component } from 'react'
import { DragLayer } from 'react-dnd-old'
import DraggingInfo from './DraggingInfo'
import ItemTypes from './ItemTypes'
import ImageSizes from './ImageSizes'
import Sizes from '../../helpers/Sizes'

import Connections from './Connections'
import IsOverPort from './IsOverPort'
import renderPrgImage from '../../source/programImg'
import renderCompImg from '../../source/Comp_Img'
import AllowedPortTypes from './AllowedPortTypes'
var Camera // This means that the collect function relies on the old Camera object before drag begins
// but this works as scale or offset cannot be changed when a drag is in progress

var layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
}

function getItemStyles(props) {
    var currentOffset = props.currentOffset
    if (!currentOffset) {
        return {
            display: 'none',
        }
    }

    var x = currentOffset.x
    var y = currentOffset.y
    const transform = 'translate(' + x + 'px, ' + y + 'px)'
    return {
        transform: transform,
        WebkitTransform: transform,
    }
}

function collect(monitor) {
    var currentOffset
    const isExpand = sessionStorage.getItem('isExpand') === 'true'

    if (
        monitor.getItemType() === ItemTypes.COMPONENT &&
        !DraggingInfo.draggingComponentOld
    ) {
        const delta = monitor.getDifferenceFromInitialOffset()
        if (delta) {
            if (delta.x > 20) {
                // Drag
                currentOffset = monitor.getClientOffset()
                if (currentOffset) {
                    currentOffset.x -=
                        (ImageSizes[ItemTypes.COMPONENT][0] * Camera.scale) / 2
                    currentOffset.y -=
                        (ImageSizes[ItemTypes.COMPONENT][1] * Camera.scale) / 2
                }
            } else if (!DraggingInfo.scrollingSidebar) {
                // Scroll
                DraggingInfo.scrollingSidebar = true // Throttling
                DraggingInfo.setSidebarScroll =
                    (delta.y,
                    () => {
                        DraggingInfo.scrollingSidebar = false
                        DraggingInfo.sidebarOldOffset = delta.y
                    })
            }
        }
    } else {
        currentOffset = monitor.getClientOffset()
        try {
            // currentOffset.x = currentOffset.x - 20
            currentOffset.x = isExpand
                ? currentOffset.x - 20
                : currentOffset.x + 180
            currentOffset.y = currentOffset.y - 20
        } catch (e) {}
    }
    return {
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: currentOffset,
    }
}

class CustomDragLayer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    renderItem(type, item) {
        var url
        let Device = sessionStorage.getItem('connectedDevice')
        const { scale } = this.props.workspace
        const { left, top } = this.props.workspace.bibox
        // let version = sessionStorage.getItem('zingVersion')

        if (type === ItemTypes.BIBOX && Device == 'Ace') {
            if (sessionStorage.getItem('shield') == 'true') {
                if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                    url = renderPrgImage('PlayComputerwithShieldImg_v1')
                } else {
                    url = renderPrgImage('PlayComputerwithShieldImg')
                }
                return (
                    <img
                        style={{
                            overflow: 'visible',
                            zIndex: 2,
                            height: '20%',
                            width: '20%',
                            left,
                            top,
                            marginLeft: '50px',
                        }}
                        src={url}
                    />
                )
            }
            if (sessionStorage.getItem('armShield') == 'true') {
                url = renderPrgImage('PlayComputerwithArmShield')
                return (
                    <img
                        style={{
                            overflow: 'visible',
                            zIndex: 2,
                            height: '20%',
                            width: '20%',
                            left,
                            top,
                            marginLeft: '50px',
                        }}
                        src={url}
                    />
                )
            }
            if (sessionStorage.getItem('peeCeeATR') == 'true') {
                if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                    url = renderPrgImage('PlayComputerwithPeeCeeATRV1')
                } else {
                    url = renderPrgImage('PlayComputerwithPeeCeeATR')
                }
                return (
                    <img
                        style={{
                            overflow: 'visible',
                            zIndex: 2,
                            height: '20%',
                            width: '20%',
                            left,
                            top,
                            marginLeft: '50px',
                        }}
                        src={url}
                    />
                )
            }
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                url = renderPrgImage('pc_v1')
            } else {
                url = renderPrgImage('PlayComputerImg')
            }
            return (
                <img
                    style={{
                        overflow: 'visible',
                        zIndex: 2,
                    }}
                    src={url}
                    width={ImageSizes[type][0] * scale}
                    height={ImageSizes[type][1] * scale}
                />
            )
        } else if (
            type === ItemTypes.BIBOX &&
            ['Roboki', 'Toki'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        ) {
            url = renderPrgImage(sessionStorage.getItem('connectedDevice'))
            let heightChange = ImageSizes[type][1] * scale
            let widthChange = ImageSizes[type][0] * scale

            return (
                <img
                    style={{
                        overflow: 'visible',
                        zIndex: 2,
                    }}
                    src={url}
                    width={widthChange}
                    height={heightChange}
                />
            )
        } else if (type === ItemTypes.BIBOX && Device == 'Humanoid') {
            url = renderPrgImage('humanoidWithPCv1')

            return (
                <img
                    src={url}
                    width={346 * scale * 0.75}
                    height={476 * scale * 0.75}
                />
            )
        } else if (type === ItemTypes.BIBOX && Device == 'Klaw') {
            url = renderPrgImage('NewklawShield')
            return (
                <img
                    src={url}
                    width={ImageSizes[type][0] * scale + 20} //346 * scale * 0.75 + 30.5
                    height={ImageSizes[type][1] * scale} //476 * scale * 0.75 - 40
                />
            )
        } else if (type === ItemTypes.BIBOX && Device == 'Tern') {
            url = 'images/Learn/tern.png'
        } else if (item.type == 'dual_splitter') {
            url = renderCompImg(item.type)
            return (
                <img
                    src={url}
                    width={ImageSizes[type][0] * scale}
                    height={ImageSizes[type][1] * scale}
                />
            )
        } else if (item.type == 'play_shield') {
            url = renderCompImg(item.type)
            return (
                <img src={url} width={'20%'} style={{ position: 'absolute' }} />
            )
        } else if (item.type == 'play_shield_v1') {
            url = renderCompImg('play_shield_v1_top')
            return (
                <img src={url} width={'20%'} style={{ position: 'absolute' }} />
            )
        } else if (item.type == 'arm_shield') {
            url = renderCompImg('arm_shield_top')
            return (
                <img src={url} width={'20%'} style={{ position: 'absolute' }} />
            )
        } else if (item.type == 'peeCee_ATR') {
            url = renderCompImg('peeCee_ATR_top')
            return (
                <img src={url} width={'20%'} style={{ position: 'absolute' }} />
            )
        } else if (item.type == 'ultrasonic_sensor') {
            url = renderCompImg(item.type)
            return (
                <img
                    src={url}
                    style={{ position: 'absolute', top: 30, left: -5 }}
                    width={ImageSizes[type][0] * scale}
                    height={ImageSizes[type][1] * scale - 20}
                />
            )
        } else if (item.type == 'linear_pot') {
            url = renderCompImg(item.type)
            return (
                <img
                    src={url}
                    style={{ position: 'absolute', top: 30, left: -5 }}
                    width={ImageSizes[type][0] * scale}
                    height={ImageSizes[type][1] * scale - 20}
                />
            )
        } else if (item.type == 'mp3') {
            url = renderCompImg(item.type)
            return (
                <img
                    src={url}
                    style={{ position: 'absolute', top: 20, left: 8.5 }}
                    width={ImageSizes[type][0] * scale}
                    height={ImageSizes[type][1] * scale - 20}
                />
            )
        } else if (item.type == 'dynamex_motor') {
            url = renderCompImg(item.type)
            return (
                <img
                    src={url}
                    style={{ position: 'absolute', top: 30, left: 8.5 }}
                    width={ImageSizes[type][0] * scale}
                    height={ImageSizes[type][1] * scale - 20}
                />
            )
        } else if (
            [
                'DHT11',
                'sound_sensor',
                'LDR_sensor',
                'LED_white',
                'LED_blue',
                'LED_green',
                'LED_red',
                'laser_diode',
                'IR_proximity',
            ].includes(item.type)
        ) {
            url = renderCompImg(item.type)
            let compTop = 0
            let compLeft = 0
            if (['DHT11', 'sound_sensor', 'LDR_sensor'].includes(item.type)) {
                compLeft = 20
            } else if (
                [
                    'LED_white',
                    'LED_blue',
                    'LED_green',
                    'LED_red',
                    'laser_diode',
                ].includes(item.type)
            ) {
                compTop = 47
                compLeft = 6
            } else if ('IR_proximity' == item.type) {
                compTop = 37
                compLeft = 6
            }
            return (
                <img
                    src={url}
                    style={{
                        position: 'absolute',
                        top: compTop,
                        left: compLeft,
                    }}
                    width={ImageSizes[type][0] * scale - 35}
                    height={ImageSizes[type][1] * scale}
                />
            )
        } else {
            url = 'images/oldImages/component_' + item.type + '.png'
            url = renderCompImg(item.type)
        }
        return (
            <img
                src={url}
                width={ImageSizes[type][0] * scale - 35}
                height={ImageSizes[type][1] * scale}
            />
        )
    }

    render() {
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        const deviceVersion = sessionStorage.getItem('deviceVersion')

        const item = this.props.item
        const itemType = this.props.itemType
        const isDragging = Boolean(DraggingInfo.isDragging && item)
        const { components } = this.props.workspace
        const { left, top } = this.props.workspace.bibox
        const isExpand = sessionStorage.getItem('isExpand') === 'true'

        //this key are  used tke the allowed port data take
        const generateAllowPortTypeKey = (Device, version) => {
            if (Device == 'Ace' && version[0] == '0') {
                if (this.props.shield) {
                    return 'SHIELD0'
                } else if (this.props.armShield) {
                    return 'ARMSHIELD0'
                } else if (this.props.peeCeeATR) {
                    return 'PEECEEATR0'
                }
                return 'ACE0'
            } else if (Device == 'Ace' && version[0] == '1') {
                if (this.props.shield) {
                    return 'SHIELD1'
                } else if (this.props.armShield) {
                    return 'ARMSHIELD1'
                } else if (this.props.peeCeeATR) {
                    return 'PEECEEATR1'
                }
                return 'ACE1'
            } else if (Device == 'Hexapod' && version[0] == '1') {
                return 'HEXAPOD1'
            } else if (Device == 'Hexapod' && version[0] == '2') {
                return 'HEXAPOD2'
            } else if (Device == 'Humanoid' && version[0] == '0') {
                return 'HUMANOID0'
            } else if (Device == 'Humanoid' && version[0] == '1') {
                return 'HUMANOID1'
            } else if (Device == 'Humanoid' && version[0] == '2') {
                return 'HUMANOID2'
            } else if (Device == 'Roboki' && version[0] == '1') {
                return 'ROBOKI1'
            } else if (Device == 'Toki' && version[0] == '1') {
                return 'TOKI1'
            } else if (Device == 'Klaw' && version[0] == '1') {
                return 'KLAW1'
            } else {
                return 'HUMANOID2'
            }
        }

        const allowedPortTypeKey = generateAllowPortTypeKey(
            connectedDevice,
            deviceVersion
        )
        let allowedPortTypeData = AllowedPortTypes[allowedPortTypeKey]

        Camera = {
            offset: this.props.workspace.offset,
            scale: this.props.workspace.scale,
        }

        var bibox = {
            left: left,
            top: top,
        }
        var extraComponent, connectedTo
        console.log('ItemType', itemType)
        //EXTRACOMPONENT IS FOR when we DnD to workSpace from slider like LED,BEEP then it will created with obj of top,left,type
        if (this.props.currentOffset && isDragging) {
            var { x, y } = this.props.currentOffset
            if (itemType === ItemTypes.BIBOX) {
                bibox.left =
                    (x - Sizes.sidebarWidth) / Camera.scale - Camera.offset.left
                bibox.top =
                    (y - Sizes.navHeight) / Camera.scale - Camera.offset.top
            } else if (itemType === ItemTypes.COMPONENT) {
                extraComponent = {
                    left:
                        (x - Sizes.sidebarWidth) / Camera.scale -
                        Camera.offset.left -
                        1,
                    top:
                        (y - Sizes.navHeight) / Camera.scale -
                        Camera.offset.top -
                        1,
                    type: item.type,
                }

                if (item.connectedTo) {
                    connectedTo = extraComponent.connectedTo = item.connectedTo
                } else {
                    connectedTo =
                        IsOverPort(
                            extraComponent,
                            bibox,
                            components,
                            allowedPortTypeData,
                            this.props.PortConnections,
                            this.props.shield,
                            this.props.armShield,
                            this.props.peeCeeATR
                        ) || DraggingInfo.newComponentPort
                    if (connectedTo)
                        extraComponent.connectedTo =
                            DraggingInfo.newComponentPort = connectedTo
                }
            }
        }

        var zIndex
        if (itemType === ItemTypes.BIBOX) zIndex = 1
        else zIndex = 3
        // connectedTo = IsOverPort(extraCompo nent, bibox, components) || DraggingInfo.newComponentPort;
        return (
            <div>
                <div
                    style={{
                        position: 'absolute',
                        top: Sizes.navHeight,
                        left: Sizes.sidebarWidth,
                        height: this.props.height,
                        width: Sizes.mainWidth,
                    }}
                >
                    {/* FOR DRAGING THE COMPONENTS IN WORKSPACE -> WHERE  WIRE, POINT_CIRCLE  are created */}
                    <Connections
                        bibox={bibox}
                        components={components}
                        Camera={Camera}
                        allowedPortTypeData={allowedPortTypeData}
                        PortConnections={this.props.PortConnections}
                        extraComponent={
                            itemType === ItemTypes.COMPONENT
                                ? extraComponent
                                : null
                        }
                        shield={this.props.shield}
                        armShield={this.props.armShield}
                        peeCeeATR={this.props.peeCeeATR}
                    />
                </div>
                {/* DRAGING WITH IMG OF COMPONENTS LIKE (LED) */}
                {isDragging && (
                    // <div style={{ ...layerStyles, zIndex }}>
                    <div
                        id="componentDragging"
                        style={{
                            // ...layerStyles,
                            position: 'fixed',
                            pointerEvents: 'none',
                            left: isExpand ? '0' : '32%',
                            top: isExpand ? '0' : '50%',
                            width: '100%',
                            height: '100%',
                            transform: isExpand
                                ? 'none'
                                : 'translate(-50%, -50%)',
                            zIndex,
                        }}
                    >
                        <div style={getItemStyles(this.props)}>
                            {this.renderItem(itemType, item)}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default DragLayer(collect)(CustomDragLayer)
