var PortValuesRangeMapping
const deviceVersion =
    sessionStorage.getItem('deviceVersion')?.startsWith('1') ||
    ['Roboki', 'Atr', 'Toki'].includes(
        sessionStorage.getItem('connectedDevice')
    )

const mapValue = () => {
    if (
        deviceVersion &&
        sessionStorage.getItem('connectedDevice') != 'Humanoid'
    )
        return { min: 0, max: 4095 }
    return { min: 0, max: 1023 }
}

const tokiExtarnalDigitalOutputComponents = {
    led_blue: function (port) {
        return { min: 0, max: 1 }
    },
    led_green: function (port) {
        return { min: 0, max: 1 }
    },
    led_red: function (port) {
        return { min: 0, max: 1 }
    },
    led_white: function (port) {
        return { min: 0, max: 1 }
    },
    laser_diode: function (port) {
        return { min: 0, max: 1 }
    },
    tact_switch: function (port) {
        return { min: 0, max: 1 }
    },
}
const tokiExtarnalanalogInputComponents = {
    ldr_sensor: function (port) {
        return mapValue()
    },
    pir_sensor: function (port) {
        return mapValue()
    },
    dht11: function (port) {
        return mapValue()
    },
    capacitative_touch_sensor: function (port) {
        return mapValue()
    },
    ir_proximity: function (port) {
        return mapValue()
    },
}

export default PortValuesRangeMapping = {
    BTRemote: function () {
        return { min: 0, max: 255 }
    },
    USBRX: function () {
        return { min: 0, max: 255 }
    },
    BTRX1: function () {
        return { min: 0, max: 255 }
    },
    BTRX2: function () {
        return { min: 0, max: 255 }
    },
    BTRX3: function () {
        return { min: 0, max: 255 }
    },
    BTRX4: function () {
        return { min: 0, max: 255 }
    },
    BTRX5: function () {
        return { min: 0, max: 255 }
    },
    BIC1: function () {
        return { min: 0, max: 255 }
    },
    BIC2: function () {
        return { min: 0, max: 255 }
    },
    BIF1: function () {
        return { min: 0, max: 255 }
    },
    BIF2: function () {
        return { min: 0, max: 255 }
    },
    BID1: function () {
        return { min: 0, max: 60000 }
    },
    BID2: function () {
        return { min: 0, max: 60000 }
    },
    SS: {
        serial_servo_motor: function (port) {
            return { min: 0, max: 4096 }
        },
    },
    SPK: {
        speaker: function (port) {
            return { min: 0, max: 255 }
        },
    },
    F12: {
        mp3: function (port) {
            return { min: 0, max: 255 }
        },
    },
    MOTOR1: {
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        relay: function (port) {
            return { min: -20, max: 20 }
        },
        electromagnet: function (port) {
            return { min: -20, max: 20 }
        },
    },
    MOTOR2: {
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        relay: function (port) {
            return { min: -20, max: 20 }
        },
        electromagnet: function (port) {
            return { min: -20, max: 20 }
        },
    },
    M1: {
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        relay: function (port) {
            return { min: -20, max: 20 }
        },
        electromagnet: function (port) {
            return { min: -20, max: 20 }
        },
    },
    M2: {
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        relay: function (port) {
            return { min: -20, max: 20 }
        },
        electromagnet: function (port) {
            return { min: -20, max: 20 }
        },
    },
    M3: {
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        relay: function (port) {
            return { min: -20, max: 20 }
        },
        electromagnet: function (port) {
            return { min: -20, max: 20 }
        },
    },
    M4: {
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        relay: function (port) {
            return { min: -20, max: 20 }
        },
        electromagnet: function (port) {
            return { min: -20, max: 20 }
        },
    },
    A: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },

        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },

        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },

        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },

        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        rgb: function (port) {
            return { min: 0, max: 255 }
        },
    },
    A1: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },

        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },

        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: 0, max: 1 }
        },

        led: function (port) {
            if (sessionStorage.getItem('connectedDevice') == 'Ace') {
                return { min: 0, max: 100 }
            } else {
                return { min: 0, max: 100 }
            }
        },
        led_1c: function (port) {
            if (sessionStorage.getItem('connectedDevice') == 'Ace') {
                return { min: 0, max: 100 }
            } else {
                return { min: 0, max: 100 }
            }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },

        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        rgb: function (port) {
            return { min: 0, max: 255 }
        },
        ...tokiExtarnalDigitalOutputComponents,
        ...tokiExtarnalanalogInputComponents,
    },
    A2: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },

        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: 0, max: 1 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            if (sessionStorage.getItem('connectedDevice') == 'Ace') {
                return { min: 0, max: 100 }
            } else {
                return { min: 0, max: 100 }
            }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        servo_extender: function (port) {
            return { min: 0, max: 1 }
        },

        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ...tokiExtarnalDigitalOutputComponents,
        ...tokiExtarnalanalogInputComponents,
    },
    I2: {
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
    },
    I1: {
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
    },
    I: {
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
    },
    H2: {
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
    },
    H1: {
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
    },
    H: {
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        geared_motor: function (port) {
            return { min: -1, max: 1 }
        },
        mini_geared_motor: function (port) {
            return { min: -1, max: 1 }
        },
        dc_motor: function (port) {
            return { min: -1, max: 1 }
        },
        dynamex_motor: function (port) {
            return { min: -1, max: 1 }
        },
    },
    G2: {
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
    },
    G1: {
        '4_in_1_sensor': function (port) {
            return { min: 0, max: 65535 }
        },
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
    },
    G: {
        '4_in_1_sensor': function (port) {
            return { min: 0, max: 65535 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        bend_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        gas_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        distance_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        sound_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        temperature_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rain_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        rotational_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        accelerometer: function (port) {
            return { min: 0, max: 1023 }
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        hall_sensor: function (port) {
            return { min: 0, max: 1023 }
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        // tact_switch_2c: function (port) {
        //   return { min: 0, max: 1 };
        // },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        geared_motor: function (port) {
            return { min: -1, max: 1 }
        },
        mini_geared_motor: function (port) {
            return { min: -1, max: 1 }
        },
        dc_motor: function (port) {
            return { min: -1, max: 1 }
        },
        dynamex_motor: function (port) {
            return { min: -1, max: 1 }
        },
    },

    F: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },

        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: 0, max: 1 }
        },

        led: function (port) {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                return { min: 0, max: 100 }
            } else {
                return { min: 0, max: 1 }
            }
        },
        led_1c: function (port) {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                return { min: 0, max: 100 }
            } else {
                return { min: 0, max: 1 }
            }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
    },
    F1: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: 0, max: 1 }
        },

        led: function (port) {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                return { min: 0, max: 100 }
            } else {
                return { min: 0, max: 1 }
            }
        },
        led_1c: function (port) {
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                return { min: 0, max: 100 }
            } else {
                return { min: 0, max: 1 }
            }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
    },
    F2: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        servo_extender: function (port) {
            return { min: 0, max: 1 }
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },

        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: 0, max: 1 }
        },

        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return deviceVersion ? { min: 0, max: 100 } : { min: 0, max: 1 }
        },
        led_1c: function (port) {
            return deviceVersion ? { min: 0, max: 100 } : { min: 0, max: 1 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
    },

    E: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },

        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },

        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },

        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },

        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },

        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
    },
    E1: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },

        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },

        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },

        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
    },
    E2: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },

        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },

        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },

        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },

        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
    },

    D: {
        joystick: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: -20, max: +20 }
        },

        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
    },
    D1: {
        joystick: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 100 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: -20, max: +20 }
        },

        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
    },
    D2: {
        joystick: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: -20, max: +20 }
        },

        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
    },

    C2: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: -20, max: +20 }
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        L293D_motor_driver: function (port) {
            return { min: 0, max: 100 }
        },
        ...tokiExtarnalDigitalOutputComponents,
    },
    C1: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: -20, max: +20 }
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        led: function (port) {
            if (sessionStorage.getItem('connectedDevice') == 'Ace') {
                return { min: 0, max: 100 }
            } else {
                return { min: 0, max: 1 }
            }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },

        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return { min: 0, max: 1023 }
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        L293D_motor_driver: function (port) {
            return { min: 0, max: 100 }
        },
        ...tokiExtarnalDigitalOutputComponents,
    },
    C: {
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: -20, max: +20 }
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
    },

    B2: {
        rgb: function (port) {
            return { min: 0, max: 255 }
        },
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: 0, max: 1 }
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
    },
    B1: {
        mp3: function (port) {
            return { min: 0, max: 255 }
        },
        rgb: function (port) {
            return { min: 0, max: 255 }
        },
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },

        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: -20, max: +20 }
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },

        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 100 }
        },
        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
        ...tokiExtarnalDigitalOutputComponents,
        ...tokiExtarnalanalogInputComponents,
    },
    B: {
        rgb: function (port) {
            return { min: 0, max: 255 }
        },
        joystick: function (port) {
            return mapValue()
        },
        extender: function (port) {
            return mapValue()
        },
        temp_dew: function (port) {
            return mapValue()
        },
        temp_gas: function (port) {
            return mapValue()
        },
        rotatory: function (port) {
            return mapValue()
        },

        pot: function (port) {
            return mapValue()
        },

        linear_pot: function (port) {
            return mapValue()
        },

        humidity: function (port) {
            return mapValue()
        },
        temp: function (port) {
            return mapValue()
        },
        gas: function (port) {
            return mapValue()
        },
        servo_extender: function (port) {
            return { min: 0, max: 1 }
        },
        dual_splitter: function (port) {
            return { min: 0, max: 225 }
        },
        led: function (port) {
            return { min: 0, max: 100 }
        },
        led_1c: function (port) {
            return { min: 0, max: 100 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        beeper: function (port) {
            return { min: 0, max: 1 }
        },

        light_sensor: function (port) {
            return mapValue()
        },
        bend_sensor: function (port) {
            return mapValue()
        },
        gas_sensor: function (port) {
            return mapValue()
        },
        distance_sensor: function (port) {
            return mapValue()
        },
        sound_sensor: function (port) {
            return mapValue()
        },
        temperature_sensor: function (port) {
            return mapValue()
        },
        rain_sensor: function (port) {
            return mapValue()
        },
        rotational_sensor: function (port) {
            return mapValue()
        },
        accelerometer: function (port) {
            return mapValue()
        },
        solar_panel: function (port) {
            return mapValue()
        },
        humidity_sensor: function (port) {
            return mapValue()
        },
        hall_sensor: function (port) {
            return mapValue()
        },
        metal_detector: function (port) {
            return { min: 0, max: 1 }
        },
        heartbeat_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch: function (port) {
            return { min: 0, max: 1 }
        },
        tact_switch_2c: function (port) {
            return { min: 0, max: 1 }
        },
        dip_switch: function (port) {
            return { min: 0, max: 1 }
        },
        dual_switch: function (port) {
            return { min: 0, max: 1 }
        },
        touch_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        pir_sensor: function (port) {
            return { min: 0, max: 1 }
        },
        mp3: function (port) {
            return { min: 0, max: 255 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        stepper_motor: function (port) {
            return { min: -20, max: +20 }
        },
        geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        mini_geared_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dc_motor: function (port) {
            return { min: -100, max: 100 }
        },
        dynamex_motor: function (port) {
            return { min: -100, max: 100 }
        },
        ultrasonic_sensor: function (port) {
            return { min: 0, max: 400 }
        },
    },

    P0: {
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
    },
    P1: {
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
    },
    P2: {
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
    },
    S1: {
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
    },
    S2: {
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },

        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
    },

    servo_motor1: {
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        ...tokiExtarnalDigitalOutputComponents,
    },
    servo_motor2: {
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor_270: function (port) {
            return { min: 0, max: 270 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
        laser: function (port) {
            return { min: 0, max: 1 }
        },
        ...tokiExtarnalDigitalOutputComponents,
    },

    //klaw range value
    serial_servo1: {
        serial_servo_motor: function (port) {
            return { min: 0, max: 4095 }
        },
    },
    serial_servo2: {
        serial_servo_motor: function (port) {
            return { min: 0, max: 4095 }
        },
    },
    PW1: {
        pwm: function (port) {
            return { min: 0, max: 180 }
        },
    },
    PW2: {
        pwm: function (port) {
            return { min: 0, max: 180 }
        },
    },
    PW3: {
        pwm: function (port) {
            return { min: 0, max: 180 }
        },
    },
    PW4: {
        pwm: function (port) {
            return { min: 0, max: 180 }
        },
    },
    PW5: {
        pwm: function (port) {
            return { min: 0, max: 180 }
        },
    },
    PW6: {
        pwm: function (port) {
            return { min: 0, max: 180 }
        },
    },
    O2: {
        servo_motor_360: function (port) {
            return { min: -100, max: +100 }
        },
        servo_motor: function (port) {
            return { min: 0, max: 180 }
        },
    },
    // Joystick1: function () {
    //     return mapValue()
    // },
    // Joystick2: function () {
    //     return mapValue()
    // },
    // Joystick3: function () {
    //     return mapValue()
    // },
    Pot1: function () {
        return { min: 0, max: 4095 }
    },
    Pot2: function () {
        return { min: 0, max: 4095 }
    },
    Pot3: function () {
        return { min: 0, max: 4095 }
    },
    Pot4: function () {
        return { min: 0, max: 4095 }
    },
    Pot5: function () {
        return { min: 0, max: 4095 }
    },
    Pot6: function () {
        return { min: 0, max: 4095 }
    },

    STPM: {
        stepper_motor: function (port) {
            return { min: 0, max: 1 }
        },
    },

    // OLD UI DATA
    FOUR_in_ONE_Sensor: {
        FOUR_in_ONE_Sensor_RED: function () {
            return { min: 0, max: 65535 }
        },
        FOUR_in_ONE_Sensor_BLUE: function () {
            return { min: 0, max: 65535 }
        },
        FOUR_in_ONE_Sensor_GREEN: function () {
            return { min: 0, max: 65535 }
        },
        FOUR_in_ONE_Sensor_DIST: function () {
            return { min: 0, max: 65535 }
        },

        FOUR_in_ONE_Sensor_MOTION: function () {
            return { min: 0, max: 4 }
        },
    },

    Switch_One: function () {
        return { min: 0, max: 1 }
    },
    Switch_Two: function () {
        return { min: 0, max: 1 }
    },
    // NEW UI DATA
    MICROPHONE: function () {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            return { min: 0, max: 4095 }
        } else {
            return { min: 0, max: 2000 }
        }
    },

    BATTERY: function () {
        return { min: 0, max: 100 }
    },

    ACCELEROMETER_X: function () {
        return { min: -32768, max: 32768 }
    },
    ACCELEROMETER_Y: function () {
        return { min: -32768, max: 32768 }
    },
    ACCELEROMETER_Z: function () {
        return { min: -32768, max: 32768 }
    },
    GYRO_X: function () {
        return { min: -2500, max: 2500 }
    },
    GYRO_Y: function () {
        return { min: -2500, max: 2500 }
    },
    GYRO_Z: function () {
        return { min: -2500, max: 2500 }
    },

    TEMPERATURE: function () {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1'))
            return { min: 0, max: 4095 }
        return { min: 0, max: 1023 }
    },

    TOUCHZERO: function () {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1'))
            return { min: 0, max: 1023 }
        return { min: 0, max: 500 }
    },
    TOUCHONE: function () {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1'))
            return { min: 0, max: 1023 }
        return { min: 0, max: 500 }
    },
    TOUCHTWO: function () {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1'))
            return { min: 0, max: 1023 }
        return { min: 0, max: 500 }
    },
    TOUCHTHREE: function () {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1'))
            return { min: 0, max: 1023 }
        return { min: 0, max: 500 }
    },
    FOUR_in_ONE_Sensor_DIST_: function () {
        return { min: 0, max: 255 }
    },
    FOUR_in_ONE_Sensor_GESTURE_: function () {
        return { min: 0, max: 4 }
    },
    FOUR_in_ONE_Sensor_LIGHT_: function () {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1'))
            return { min: 0, max: 1023 }
        return { min: 0, max: 255 }
    },
    FOUR_in_ONE_Sensor_RED_: function () {
        return { min: 0, max: 255 }
    },
    FOUR_in_ONE_Sensor_BLUE_: function () {
        return { min: 0, max: 255 }
    },
    FOUR_in_ONE_Sensor_GREEN_: function () {
        return { min: 0, max: 255 }
    },
}
