import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import renderImage from '../../source/importImg'
import $ from 'jquery'
import packageJson from '../../../package.json'
import './SelectDevice.css'
import { connect } from 'react-redux'
import { webSerialConnect } from '../../redux/actions'
// let DEVICES = [
//     { name: 'PeeCee Alpha', id: 'Ace', src: 'PC' },
//     { name: 'PeeCee Beta', id: 'Ace', src: 'PCv1' },
//     { name: 'Zing', id: 'Humanoid', src: 'Zing' },
// ]

function SelectDevice(props) {
    const history = useHistory()
    const [DEVICES, setDEVICES] = useState([])
    useEffect(() => {
        sessionStorage.setItem('manualConnection', true)
        let devices = JSON.parse(sessionStorage.getItem('matchedDevices'))
        console.log(devices)
        setDEVICES(devices)
    }, [])
    useEffect(() => {
        const num = DEVICES.length
        $(`#${Math.floor(num / 2)}`).addClass('active')
        $(`#${Math.floor(num / 2)}`)
            .prev()
            .addClass('prev')
        $(`#${Math.floor(num / 2)}`)
            .next()
            .addClass('next')

        const slide = $('.active').width()

        // centering container for even objects
        if (num % 2 == 0) {
            $('.container').animate({ left: '-=' + (slide - 80) })
            console.log('SILDE2222222', slide)
        }

        // triggering auto focus on Play Computer

        // setTimeout(() => {
        //     $('.active').prev().trigger('click')
        // }, 500)
    }, [DEVICES])

    // useEffect(async () => {
    // const filters = [
    //     { usbVendorId: 0x1a86, usbProductId: 0x7523 },
    //     { usbVendorId: 0x303a, usbProductId: 0x1001 },
    // ]
    // const portList = await navigator.serial.getPorts({
    //     filters,
    // })
    // let port = portList[0]

    // props.webSerialConnect({ port })
    // }, [])
    const handleClick = async (event) => {
        sessionStorage.removeItem('slideDirection')

        const id = event.target.id

        const slide = $('.active').width()
        if ($(`#${id}`).hasClass('next')) {
            $('.container')
                .stop(false, true)
                .animate({ left: '-=' + slide })
        } else if ($(`#${id}`).hasClass('prev')) {
            $('.container')
                .stop(false, true)
                .animate({ left: '+=' + slide })
        } else if ($(`#${id}`).hasClass('active')) {
            sessionStorage.setItem('connectedDevice', DEVICES[id].id)
            const filters = [
                { usbVendorId: 0x1a86, usbProductId: 0x7523 },
                { usbVendorId: 0x303a, usbProductId: 0x1001 },
                { usbVendorId: 0x1a86, usbProductId: 0x8040 },
            ]
            const portList = await navigator.serial.getPorts({
                filters,
            })
            let port = portList[0]

            props.webSerialConnect({ port })
            // props.webSerialConnect({ port: res.port })

            sessionStorage.setItem('deviceVersion', DEVICES[id].version)
            // sessionStorage.setItem('zingVersion', '0.0.0')

            history.push('/Selection')
        }

        $(`#${id}`).removeClass('prev next')
        $(`#${id}`).siblings().removeClass('prev active next')

        $(`#${id}`).addClass('active')
        $(`#${id}`).prev().addClass('prev')
        $(`#${id}`).next().addClass('next')
    }

    // Event Listeners for keypress and scroll
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('wheel', handleWheel)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('wheel', handleWheel)
        }
    }, [])

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') $('.active').next().trigger('click')
        else if (event.key === 'ArrowLeft') $('.active').prev().trigger('click')
    }

    const handleWheel = (event) => {
        if (event.deltaY > 0) $('.active').next().trigger('click')
        else if (event.deltaY < 0) $('.active').prev().trigger('click')
    }

    return (
        <div className="container mx-5">
            <div className="carousal_container">
                {DEVICES.map((device, index) => (
                    <div
                        key={index}
                        id={index}
                        className="ui-card"
                        onClick={handleClick}
                    >
                        <img
                            id={index}
                            src={renderImage(`${device.src}`)}
                            style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '100%',
                                pointerEvent: 'none',
                            }}
                        />
                        <p
                            id={index}
                            style={{ color: 'black' }}
                            className="text-center"
                        >
                            {device.name}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        webserialPort: state.webSerial,
        worker: state.worker,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectDevice)
