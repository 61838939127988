// import Pot from '../Assets/Bisoft_UI/Accessories/newComponents/component_pot.png'
import pot from '../Assets/Bisoft_UI/Accessories/External_NewComponents/pot.png'
// import distanceSensor from '../Assets/Bisoft_UI/Accessories/newComponents/component_distance_sensor.png'
import distanceSensor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/range@2x.png'
// import joystick from '../Assets/Bisoft_UI/Accessories/newComponents/component_joystick.png'
import joystick from '../Assets/Bisoft_UI/Accessories/External_NewComponents/joystick@2x.png'
// import dualswitch from '../Assets/Bisoft_UI/Accessories/newComponents/component_dual_switch.png'
import dualswitch from '../Assets/Bisoft_UI/Accessories/External_NewComponents/dualswitch.png'
// import laser from '../Assets/Bisoft_UI/Accessories/newComponents/component_laser.png'
import laser from '../Assets/Bisoft_UI/Accessories/External_NewComponents/laser.png'
// import lightsensor from '../Assets/Bisoft_UI/Accessories/newComponents/component_light_sensor.png'
import lightsensor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/light@2x.png'

import servomotor from '../Assets/Bisoft_UI/Accessories/newComponents/component_servo_motor.png'
import servomotor_270 from '../Assets/Bisoft_UI/Accessories/newComponents/servo_270.png'
import servomotor_360 from '../Assets/Bisoft_UI/Accessories/newComponents/component_servo_motor_360.png'
// import led from '../Assets/Bisoft_UI/Accessories/newComponents/component_led.png'
import led_1c from '../Assets/Bisoft_UI/Accessories/External_NewComponents/led1c.png'
// import tactswitch from '../Assets/Bisoft_UI/Accessories/newComponents/component_tact_switch.png'
import tactswitch from '../Assets/Bisoft_UI/Accessories/External_NewComponents/tactSwitch.png'

// import touchsensor from '../Assets/Bisoft_UI/Accessories/newComponents/component_touch_sensor.png'
import touchsensor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/touch.png'

// import led_1c from '../Assets/Bisoft_UI/Accessories/newComponents/component_led_1c.png'
import led_2c from '../Assets/Bisoft_UI/Accessories/External_NewComponents/led2.png'
// not available
import tactswitch_2c from '../Assets/Bisoft_UI/Accessories/newComponents/component_tact_switch_2c.png'
// import ultrasonicsensor from '../Assets/Bisoft_UI/Accessories/newComponents/component_ultrasonic_sensor.png'
import ultrasonicsensor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/ultrasonic@2x.png'

// import RGB from '../Assets/Bisoft_UI/Accessories/newComponents/component_RGB.png'
import RGB from '../Assets/Bisoft_UI/Accessories/External_NewComponents/rgb@2x.png'
// import OLED from '../Assets/Bisoft_UI/Accessories/newComponents/component_OLED.png'
import OLED from '../Assets/Bisoft_UI/Accessories/External_NewComponents/oled@2x.png'
// import mp3 from '../Assets/Bisoft_UI/Accessories/newComponents/component_mp3.png'
import mp3 from '../Assets/Bisoft_UI/Accessories/External_NewComponents/mp3@2x.png'
// import dipswitch from '../Assets/Bisoft_UI/Accessories/newComponents/component_dip_switch.png'
import dipswitch from '../Assets/Bisoft_UI/Accessories/External_NewComponents/dipSwitch.png'

// import rainsensor from '../Assets/Bisoft_UI/Accessories/newComponents/component_rain_sensor.png'
import rainsensor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/rain.png'
// import linearpot from '../Assets/Bisoft_UI/Accessories/newComponents/component_linear_pot.png'
import linear_pot from '../Assets/Bisoft_UI/Accessories/External_NewComponents/linearpot.png'
// import humidity from '../Assets/Bisoft_UI/Accessories/newComponents/component_humidity.png'
import humidity from '../Assets/Bisoft_UI/Accessories/External_NewComponents/humidity.png'
// import metaldetector from '../Assets/Bisoft_UI/Accessories/newComponents/component_metal_detector.png'
import metal_detector from '../Assets/Bisoft_UI/Accessories/External_NewComponents/metal.png'
// import extender from '../Assets/Bisoft_UI/Accessories/newComponents/component_extender.png'
import extender from '../Assets/Bisoft_UI/Accessories/External_NewComponents/extender.png'
// import temp from '../Assets/Bisoft_UI/Accessories/newComponents/component_temperature_sensor.png'
import temp from '../Assets/Bisoft_UI/Accessories/External_NewComponents/temperature.png'
// import gas from '../Assets/Bisoft_UI/Accessories/newComponents/component_gas.png'
import gas from '../Assets/Bisoft_UI/Accessories/External_NewComponents/gas.png'
import pc_motor_driver from '../Assets/Bisoft_UI/Accessories/newComponents/component_pc_motor_driver.png'
// import dual_splitter from '../Assets/Bisoft_UI/Accessories/newComponents/component_dual_splitter.png'
import dual_splitter from '../Assets/Bisoft_UI/Accessories/External_NewComponents/dual_Splitter.png'
// import dc_motor from '../Assets/Bisoft_UI/Accessories/newComponents/component_dc_motor.png'
import dc_motor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/dcmotor.png'
import geared_motor from '../Assets/Bisoft_UI/Accessories/newComponents/component_geared_motor.png'
// import mini_geared_motor from '../Assets/Bisoft_UI/Accessories/newComponents/component_mini_geared_motor.png'
import mini_geared_motor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/n20@2x.png'
// import play_shield from '../Assets/Bisoft_UI/Accessories/newComponents/component_play_shield.png'
import play_shield from '../Assets/Bisoft_UI/Accessories/External_NewComponents/playShield.png'
// import play_shield_v1 from '../Assets/Bisoft_UI/Accessories/newComponents/component_play_shield_v1@3x.png'
import play_shield_v1 from '../Assets/Bisoft_UI/Accessories/External_NewComponents/pcshield@3x.png'
import play_shield_v1_top from '../Assets/Bisoft_UI/Accessories/newComponents/component_play_shield_v1_top.png'

import play_shield_top from '../Assets/Bisoft_UI/Accessories/newComponents/component_play_shield_top.png'
// import arm_shield from '../Assets/Bisoft_UI/Accessories/newComponents/component_arm_shield.png'
import arm_shield from '../Assets/Bisoft_UI/Accessories/External_NewComponents/playarmshield.png'
// import arm_shield_top from '../Assets/Bisoft_UI/Accessories/newComponents/component_arm_shield_top.png'
import arm_shield_top from '../Assets/Bisoft_UI/Accessories/External_NewComponents/pcarmshieldtop.png'
// import peeCee_ATR from '../Assets/Bisoft_UI/Accessories/newComponents/PeeCeeATRNe.png'
import peeCee_ATR from '../Assets/Bisoft_UI/Accessories/External_NewComponents/peeCee_ATR.png'
import peeCee_ATR_top from '../Assets/Bisoft_UI/Accessories/newComponents/PeeCeeATRNew1.png'

// import serial_servo_motor from '../Assets/Bisoft_UI/Accessories/newComponents/component_serial_servo_motor.png'
import serial_servo_motor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/serial_servo.png'
import speaker from '../Assets/Bisoft_UI/Accessories/newComponents/component_speaker.png'

//new _Componets for PCVersion0.1
import component_ultrasonic_sensor_new from '../Assets/Bisoft_UI/Accessories/newComponents/component_ultrasonic_sensor.png'
import OLED_new from '../Assets/Bisoft_UI/Accessories/newComponents/OLED_NEW.png'
import RGB_new from '../Assets/Bisoft_UI/Accessories/newComponents/component_RGB.png'
import mini_geared_motor_new from '../Assets/Bisoft_UI/Accessories/newComponents/component_mini_geared_motor.png'

// new_Componets for PCVersion1.0
import dipSwitch_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/dipswitch@2x.png'
import joytstick_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/joystick.png'
import led2c_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/led.png'
import light_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/light@2.png'
import pot_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/pot@2x.png'
import rain_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/rain@2x.png'
import range from '../Assets/Bisoft_UI/Accessories/External_NewComponents/range.png'
import switch_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/switch@2x.png'
import led1c_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/led2_New.png'
import tactSwitch_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/button@2x.png'
import tactSwitch2c_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/button2c.png'
import laser_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/newlaser.png'
import dual_splitter_New from '../Assets/Bisoft_UI/Accessories/External_NewComponents/motorbridge2x.png'
import soundcard from '../Assets/Bisoft_UI/Accessories/External_NewComponents/soundcard.png'

//Roboki Dynamex motor image
import dynamex_motor from '../Assets/Bisoft_UI/Accessories/External_NewComponents/dynamexMotor@2x.png'

//klaw images
import PWM from '../Assets/Bisoft_UI/Accessories/External_NewComponents/serial_servo.png'

// toki new external components
import LED_blue from '../Assets/Bisoft_UI/Accessories/toki_New_components/blueLED@2x.png'
import LED_green from '../Assets/Bisoft_UI/Accessories/toki_New_components/greenLED@2x.png'
import LED_red from '../Assets/Bisoft_UI/Accessories/toki_New_components/redLED@2x.png'
import LED_white from '../Assets/Bisoft_UI/Accessories/toki_New_components/whiteLED@2x.png'
import laser_diode from '../Assets/Bisoft_UI/Accessories/toki_New_components/LaserDiode@2x.png'
import L293D_motor_driver from '../Assets/Bisoft_UI/Accessories/toki_New_components/L293DMotor@2x.png'
import DHT11 from '../Assets/Bisoft_UI/Accessories/toki_New_components/DHT11@2x.png'
import IR_proximity from '../Assets/Bisoft_UI/Accessories/toki_New_components/IRProximity@2x.png'
import LDR_sensor from '../Assets/Bisoft_UI/Accessories/toki_New_components/LDRSensor@2x.png'
import PIR_sensor from '../Assets/Bisoft_UI/Accessories/toki_New_components/PIRSensor@2x.png'
import raindrop_sensing from '../Assets/Bisoft_UI/Accessories/toki_New_components/RaindropSensing@2x.png'
import capacitative_touch_sensor from '../Assets/Bisoft_UI/Accessories/toki_New_components/CapacitativeTouch@2x.png'
import sound_sensor from '../Assets/Bisoft_UI/Accessories/toki_New_components/SoundSensor@2x.png'
import ultrasonicToki from '../Assets/Bisoft_UI/Accessories/toki_New_components/ultrasonicToki@2x.png'

function renderCompImg(name) {
    let connnectedDevice = sessionStorage.getItem('connectedDevice')
    let isPeeCee1 =
        sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
        ['Ace'].includes(sessionStorage.getItem('connectedDevice'))

    let isHexZing12 =
        ['1', '2'].includes(
            (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
        ) &&
        ['Humanoid', 'Hexapod'].includes(
            sessionStorage.getItem('connectedDevice')
        )

    let isTokiComponents = ['Toki'].includes(connnectedDevice)

    let isCompImg_onePointZero =
        isPeeCee1 ||
        isHexZing12 ||
        ['Roboki', 'Klaw', 'Toki'].includes(
            sessionStorage.getItem('connectedDevice')
        )
            ? true
            : false
    switch (name) {
        case 'pot':
            var type = isCompImg_onePointZero ? pot_New : pot
            return type
        case 'play_shield':
            var type = play_shield
            return type

        case 'play_shield_v1':
            var type = play_shield_v1
            return type

        case 'play_shield_v1_top':
            var type = play_shield_v1_top
            return type

        case 'play_shield_top':
            var type = play_shield_top
            return type
        case 'dc_motor':
            var type = dc_motor
            return type
        case 'geared_motor':
            var type = geared_motor
            return type
        case 'mini_geared_motor':
            var type = isCompImg_onePointZero
                ? mini_geared_motor
                : mini_geared_motor_new
            return type
        case 'distance_sensor':
            var type = isCompImg_onePointZero ? range : distanceSensor
            return type

        case 'dual_switch':
            var type = isCompImg_onePointZero ? switch_New : dualswitch
            return type

        case 'joystick':
            var type = isCompImg_onePointZero ? joytstick_New : joystick
            return type

        case 'laser':
            var type = isCompImg_onePointZero ? laser_New : laser
            return type

        case 'led':
            var type = isCompImg_onePointZero ? led2c_New : led_2c
            return type

        case 'servo_motor':
            var type = servomotor
            return type
        case 'servo_motor_270':
            var type = servomotor_270
            return type
        case 'servo_motor_360':
            var type = servomotor_360
            return type

        case 'light_sensor':
            var type = isCompImg_onePointZero ? light_New : lightsensor
            return type

        case 'tact_switch':
            var type = isCompImg_onePointZero ? tactSwitch_New : tactswitch
            return type

        case 'touch_sensor':
            var type = touchsensor
            return type

        case 'ultrasonic_sensor':
            var type = isTokiComponents
                ? ultrasonicToki
                : isCompImg_onePointZero
                ? ultrasonicsensor
                : component_ultrasonic_sensor_new
            return type

        case 'led_1c':
            var type = isCompImg_onePointZero ? led1c_New : led_1c
            return type

        case 'tact_switch_2c':
            var type = isCompImg_onePointZero ? tactSwitch2c_New : tactswitch_2c
            return type

        case 'RGB':
            var type = isCompImg_onePointZero ? RGB : RGB_new
            return type

        case 'OLED':
            var type = isCompImg_onePointZero ? OLED : OLED_new
            return type

        case 'mp3':
            var type = soundcard
            //mp3
            return type

        case 'dip_switch':
            var type = isCompImg_onePointZero ? dipSwitch_New : dipswitch
            return type

        case 'linear_pot':
            var type = linear_pot
            return type

        case 'humidity':
            var type = humidity
            return type

        case 'metal_detector':
            var type = metal_detector
            return type

        case 'extender':
            var type = extender
            return type

        case 'temperature_sensor':
            var type = temp
            return type

        case 'gas':
            var type = gas
            return type

        case 'rain_sensor':
            var type = isTokiComponents
                ? raindrop_sensing
                : isCompImg_onePointZero
                ? rain_New
                : rainsensor
            return type
        case 'pc_motor_driver':
            var type = pc_motor_driver
            return type

        case 'dual_splitter':
            var type = isCompImg_onePointZero
                ? dual_splitter_New
                : dual_splitter
            return type

        case 'play_shield_top':
            var type = play_shield_top
            return type
        case 'peeCee_ATR':
            return peeCee_ATR
        case 'peeCee_ATR_top':
            return peeCee_ATR_top
        case 'arm_shield':
            return arm_shield
        case 'arm_shield_top':
            return arm_shield_top

        case 'servo_motor':
            var type = servomotor
            return type

        case 'servo_motor_360':
            var type = servomotor_360
            return type

        case 'serial_servo_motor':
            return serial_servo_motor

        case 'speaker':
            return
        case 'dynamex_motor':
            return dynamex_motor
        case 'PWM':
            return PWM

        // toki new external components
        case 'LED_white':
            return LED_white
        case 'LED_blue':
            return LED_blue
        case 'LED_green':
            return LED_green
        case 'LED_red':
            return LED_red
        case 'sound_sensor':
            return sound_sensor
        // case 'raindrop_sensing':
        //     return raindrop_sensing
        case 'laser_diode':
            return laser_diode
        case 'capacitative_touch_sensor':
            return capacitative_touch_sensor
        case 'L293D_motor_driver':
            return L293D_motor_driver
        case 'PIR_sensor':
            return PIR_sensor
        case 'DHT11':
            return DHT11
        case 'LDR_sensor':
            return LDR_sensor
        case 'IR_proximity':
            return IR_proximity
    }
}
export default renderCompImg
