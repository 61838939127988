import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
    showModal,
    webSerialConnect,
    deviceDetails,
    deviceMismatch,
} from '../../redux/actions/index'
import renderImage from '../../source/importImg'
import DeviceSelectStyle from './DeviceSelect.module.css'

import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import packageJson from '../../../package.json'
import { compareVersion } from '../../utils/verionControl'
import {
    connectSerialDevice,
    connectSerialDeviceInitial,
    connectUSBDevice,
    openUSBReadComPort,
} from '../WebSerialConnection/SerialUSBConnect'
import cacheAssets from '../../utils/cacheAssets'
import { getEventListeners } from 'events'
import { fetchVersion } from './FlashFiles'

const ENDPOINT = 'localhost:3008'
//let socket = socketIOClient("http://localhost:3008");

const getPlodeVersion = (connectedDevice) => {
    if (connectedDevice === 'Ace')
        return packageJson['plode-versions']['plode-pc']
    // else if (connectedDevice === "Humanoid")
    else return packageJson['plode-versions']['plode-humanoid']
}

const getVersionArguments = {
    Ace0: {
        deviceName: 'Playcomputer',
        version: '0.1',
    },
    Ace1: {
        deviceName: 'Playcomputer',
        version: '1.0',
    },
    Roboki: {
        deviceName: 'Roboki',
        version: '1.0',
    },
    Humanoid: {
        deviceName: 'Zing',
        version: '2.0',
    },
    Hexapod: {
        deviceName: 'Crawlee',
        version: '2.0',
    },
    Klaw: {
        deviceName: 'Klaw',
        version: '1.0',
    },
    Toki: {
        deviceName: 'Toki',
        version: '1.0',
    },
}
const DeviceSelect = (props) => {
    //for stay in selection page on click the broswer back button
    window.history.forward()
    const [variDevice, setvariDevice] = useState(false)
    const [isDeviceMismatch, setIsDeviceMismatch] = useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [showDialogs, setShowDialogs] = useState({
        showDialog: false,
        showConnecting: false,
        text: '',
    })
    const isDeviceMismatchRef = useRef(isDeviceMismatch)
    //unable to get version,click OK to continue to manual selection

    let history = useHistory()

    useEffect(() => {
        isDeviceMismatchRef.current = isDeviceMismatch
    }, [isDeviceMismatch])

    useEffect(() => {
        // sessionStorage.removeItem('deviceDetails')
        if (
            props.webserialPort.isConnected &&
            sessionStorage.getItem('connectedDevice') &&
            variDevice
        ) {
            history.push('/Selection')
        }
    })

    // useEffect(() => {
    //     props.worker.onmessage = (e) => {
    //         console.log("hiii",e.data)
    //         if (e.data.type == 'connected' && e.data.value) {
    //             console.log('value in connection', e.data.value)
    //             props.worker.onmessage=null
    //         }
    //     }
    //     return()=>{
    //         props.worker.onmessage=null
    //     }
    // })
    useEffect(() => {
        sessionStorage.setItem('uploading', false)
        if (!props.webserialPort.isConnected) {
            sessionStorage.removeItem('deviceVersion')
            sessionStorage.removeItem('connectedDevice')
            sessionStorage.removeItem('manualConnection')
            sessionStorage.removeItem('matchedDevices')
            sessionStorage.removeItem('user')
            sessionStorage.removeItem('webSerialPortList')
            sessionStorage.removeItem('isPlay')
        }
    }, [])

    useEffect(() => {
        sessionStorage.removeItem('connectText')
        sessionStorage.removeItem('verPopUpCount')
        if (JSON.parse(sessionStorage.getItem('autoCunnection'))) {
            if (props.webserialPort.isConnected) setvariDevice(true)
            sessionStorage.setItem('autoCunnection', 'false')
        } else {
            setvariDevice(false)
        }
    }, [props.webserialPort.isConnected])

    const gobackUrl = () => {
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)
        sessionStorage.removeItem('allowedDevice')
        history.push('/kitSelection')
    }

    const [isHelp, setHelp] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    const scanDevice = async () => {
        // Prompt user to select an PlayComputer device.
        sessionStorage.setItem('connectText', 'hello')
        let res = await connectSerialDeviceInitial(
            { isAutomatic: false },
            props.worker
        )
        console.log('resp', res, props)

        if (res !== undefined && res.isPortOpen) {
            const deviceVersion = sessionStorage.getItem('deviceVersion')
            // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
            const connectedDevice = sessionStorage.getItem('connectedDevice')

            props.deviceDetails({ connectedDevice, deviceVersion }) //zingVersion
            props.webSerialConnect({ port: res.port })

            sessionStorage.setItem('manualConnection', false)
            setvariDevice(true)
        } else if (res && !res.isPortOpen) {
            props.deviceMismatch({
                deviceMismatched: res.allowedDeviceObj,
            })
            props.webSerialConnect({ port: res.port })
            setIsDeviceMismatch(true)
        }
        // else  props.webSerialConnect({ port: res })
    }

    const handleTextChange = useCallback(
        (e) => {
            const data = e.detail
            console.log(isDeviceMismatchRef.current)
            if (!isDeviceMismatchRef.current)
                setShowDialogs({
                    ...showDialogs,
                    showConnecting: true,
                    text: data,
                })
            else
                setShowDialogs({
                    ...showDialogs,
                    showConnecting: false,
                    text: '',
                })
        },
        [isDeviceMismatch]
    )

    //if i remove the usb deviceMismatched object set empty object
    useEffect(() => {
        if (!props.isConnected) {
            props.deviceMismatch({
                deviceMismatched: {},
            })
        }
    }, [props.isConnected])

    useEffect(() => {
        console.log('hiiedwefwef', props.device.deviceMismatched)
        if (Object.keys(props.device.deviceMismatched).length > 0)
            setIsDeviceMismatch(true)
        else setIsDeviceMismatch(false)
    }, [props.device.deviceMismatched])
    useEffect(() => {
        document.addEventListener('custom', handleTextChange)
        return () => document.removeEventListener('custom', handleTextChange)
    }, [])

    const handleDialogModalClose = () => {
        setShowDialogs({ ...showDialogs, showDialog: false })
    }

    const handleDialogModalOpen = () => {
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
        // if (props.webserialPort.isConnected) history.push('/Selection')
        // else
        setShowDialogs({ ...showDialogs, showDialog: true })
    }

    const optionsToSelect = [
        {
            text: 'YES',
            do: async () => {
                let allowedDevice = sessionStorage.getItem('allowedDevice')
                sessionStorage.setItem(
                    'connectedDevice',
                    allowedDevice?.includes('Ace') ? 'Ace' : allowedDevice
                )
                let { latestVersion } = await fetchVersion(
                    'https://embedded.plode.org/version-list',
                    getVersionArguments[allowedDevice]
                        ? getVersionArguments[allowedDevice].deviceName
                        : 'Playcomputer',
                    getVersionArguments[allowedDevice]
                        ? getVersionArguments[allowedDevice].version
                        : '1.0'
                )
                // let version = latestVersion.replace(/[a-zA-Z]/g, '')
                // latestVersion = latestVersion.replace('PC', '')
                sessionStorage.setItem(
                    'deviceVersion',
                    latestVersion.slice(0, latestVersion.length - 2)
                )
                history.push('/Selection')
            },
        },
        { text: 'NO', do: handleDialogModalClose },
    ]

    useEffect(() => {
        let assetsToBeCached = [
            renderImage('Connect'),
            renderImage('ZingConnect'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const handleDevice = (res) => {
        if (res == 'yes') {
            let mismatchedDevice = props.device.deviceMismatched
            sessionStorage.setItem('connectedDevice', mismatchedDevice.id)
            sessionStorage.setItem('deviceVersion', mismatchedDevice.version)
            sessionStorage.setItem('manualConnection', false)
            props.deviceDetails({
                connectedDevice: mismatchedDevice.id,
                deviceVersion: mismatchedDevice.version,
            }) //zingVersion

            setIsDeviceMismatch(false)
            setvariDevice(true)
            props.deviceMismatch({
                deviceMismatched: {},
            })
        } else {
            props.deviceMismatch({
                deviceMismatched: {},
            })
            setIsDeviceMismatch(false)
            sessionStorage.removeItem('allowedDevice')
            history.push('/kitSelection')
        }
        sessionStorage.removeItem('allowedDevice')
    }

    //get device name that show in ui
    const getDeviceName = (device) => {
        switch (device) {
            case 'Ace':
            case 'Ace1':
                return 'PeeCee'
            case 'Humanoid':
                return 'Zing'
            case 'Hexapod':
                return 'Crawl-e'
            case 'Klaw':
                return 'Klaw-b'
            case 'Roboki':
                return 'Roboki'
            case 'Klaw':
                return 'Klaw-b'
            default:
                return device
        }
    }

    console.log(isDeviceMismatch)
    return (
        <>
            <DialogModal
                mismatchShow={isDeviceMismatch && !showDialogs.showConnecting}
                text={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2.5vh',
                            }}
                        >
                            A device mismatch found!
                        </p>
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2vh',
                                display: 'flex',
                                gap: '1rem',

                                textAlign: 'center',
                                // border: '2px solid red',
                            }}
                        >
                            <span>
                                Selected Device :&nbsp;
                                {getDeviceName(
                                    props.device.deviceMismatched.selected
                                )}
                            </span>
                            <span>
                                Connected Device :&nbsp;
                                {getDeviceName(
                                    props.device.deviceMismatched.id
                                )}
                            </span>
                        </p>
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2vh',
                            }}
                        >
                            Would you like to continue with connected device?
                        </p>
                    </div>
                }
                showCloseBtn={true}
                handleDialogModalClose={() => {
                    setIsDeviceMismatch(false)
                    props.deviceMismatch({
                        deviceMismatched: {},
                    })
                }}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => handleDevice('yes'),
                    },
                    // {
                    //     text: 'NO',
                    //     do: () => handleDevice('no'),
                    // },
                ]}
            />
            <DialogModal
                show={showDialogs.showDialog}
                text="Do you want to continue without your device being connected ?"
                showCloseBtn={false}
                handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={optionsToSelect}
            />
            <DialogModal
                connection={showDialogs.showConnecting}
                text={showDialogs.text}
                optionsToSelect={[
                    {
                        text: 'OK',
                        do: () => {
                            setShowDialogs({
                                ...showDialogs,
                                showConnecting: false,
                                text: '',
                            })

                            history.push('/kitSelection')
                        },
                    },
                ]}
            />
            <div className={DeviceSelectStyle.Connect_Device}>
                <div className={DeviceSelectStyle.Connect_Header}>
                    {isHelp ? (
                        <div>
                            <img
                                className={DeviceSelectStyle.back_btn}
                                style={{ zIndex: '-1' }}
                                src={renderImage('backBtn')}
                                onClick={gobackUrl}
                            ></img>{' '}
                        </div>
                    ) : (
                        <div>
                            <img
                                className={DeviceSelectStyle.back_btn}
                                style={{ zIndex: '1000' }}
                                src={renderImage('backBtn')}
                                onClick={gobackUrl}
                            ></img>{' '}
                        </div>
                    )}

                    <div>
                        {isHelp == false ? (
                            <div className="header_button">
                                <img
                                    className={DeviceSelectStyle.helpBtn}
                                    src={renderImage('helpBtnInActive')}
                                    onClick={handleHelpBtn}
                                ></img>
                                <span className="tooltiptext">
                                    {' '}
                                    <p>Help</p>
                                </span>
                            </div>
                        ) : (
                            <div
                                style={{
                                    height: '100vh',
                                    width: '100vw',
                                    background: '#fff',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    zIndex: '1000',
                                    position: 'absolute',
                                }}
                            >
                                <div
                                    style={{
                                        transform: 'translate(-50%,-50%)',
                                        top: '47%',
                                        left: '50%',
                                        zIndex: '10',
                                        position: 'absolute',
                                        width: '65vw',
                                    }}
                                >
                                    <div>
                                        <img
                                            src={
                                                sessionStorage.getItem(
                                                    'connectedDevice'
                                                ) === 'Humanoid'
                                                    ? renderImage('ZingConnect')
                                                    : renderImage('Connect')
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        ></img>
                                    </div>
                                    <div className={DeviceSelectStyle.instr}>
                                        <p
                                            className={
                                                DeviceSelectStyle.instr_P
                                            }
                                        >
                                            {' '}
                                            Help Button will explain all the
                                            features and functionality of the
                                            entire app for each screen{' '}
                                        </p>
                                    </div>
                                    <div className={DeviceSelectStyle.instr2}>
                                        <p
                                            className={
                                                DeviceSelectStyle.instr2_P
                                            }
                                        >
                                            Manual scan will list all the
                                            Playcomputer devices if multiple
                                            devices are ON
                                        </p>
                                    </div>
                                    <div className={DeviceSelectStyle.instr3}>
                                        <p
                                            className={
                                                DeviceSelectStyle.instr3_P
                                            }
                                        >
                                            Skip button will skip the scanning
                                            and connecting over bluetooth to
                                            playcomputer.If you skip you will
                                            not be able to send data to
                                            playcomputer.{' '}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isHelp ? (
                            <div className="web_button">
                                <img
                                    className={DeviceSelectStyle.Cross}
                                    src={renderImage('clos')}
                                    onClick={handleHelpBtn}
                                ></img>
                                <span className="tooltiptext">
                                    {' '}
                                    <p>
                                        {' '}
                                        Device <br />
                                        connectivity
                                    </p>
                                </span>
                            </div>
                        ) : null}
                        {/* <img className="DeviceSelectStyle.Help_btn" src={help}></img> */}
                    </div>
                </div>

                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : ''
                    }`}
                    style={{ position: 'relative' }}
                >
                    <div className={DeviceSelectStyle.Connect_div}>
                        <img
                            className={DeviceSelectStyle.Pc_Image}
                            src={renderImage('PC_New')}
                        ></img>
                        <img
                            className={DeviceSelectStyle.Computer_Img}
                            src={renderImage('Computer')}
                        ></img>
                    </div>
                </div>

                <div></div>
                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : ''
                    }`}
                    style={{ position: 'relative' }}
                >
                    {' '}
                    <div>
                        <img
                            className={DeviceSelectStyle.Scan_Button}
                            src={renderImage('reconnectSvg')}
                            onClick={() => {
                                scanDevice()
                                // connect();
                            }}
                        ></img>

                        {/* <Link to="/Connect"> */}
                        <h4
                            className={DeviceSelectStyle.Scan_txt}
                            onClick={scanDevice}
                            // onClick={gobackUrl}
                        >
                            Scan Device
                        </h4>
                    </div>
                </div>

                <div
                    className={DeviceSelectStyle.Connect_Footer}
                    style={isHelp ? { zIndex: '-1' } : null}
                >
                    <div>
                        <p className={DeviceSelectStyle.Instructions}>
                            Connect the device to your system using the USB
                            cable provided
                        </p>
                    </div>
                    <div>
                        <p className={DeviceSelectStyle.version}>
                            v{packageJson.version}
                        </p>
                    </div>
                    {/* <Link to="/conformation"> */}
                    <div>
                        <img
                            className={DeviceSelectStyle.Skip_Button}
                            src={renderImage('skip')}
                            onClick={handleDialogModalOpen}
                        ></img>
                    </div>
                    {/* </Link> */}
                </div>
            </div>
        </>
    )
}

// export default DeviceSelect;
const mapStateToProps = (state) => {
    return {
        webserialPort: state.webSerial,
        worker: state.worker,
        device: state.device,
        deviceMismatch: state.deviceMismatch,
        isConnected: state.webSerial.isConnected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
        showModal: (data) => {
            dispatch(showModal(data))
        },
        deviceDetails: (data) => {
            dispatch(deviceDetails(data))
        },
        deviceMismatch: (data) => {
            dispatch(deviceMismatch(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSelect)
