module.exports = {
    SHIELD1: {
        RGB: ['B', 'B1'],
        led: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        led_1c: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        laser: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        beeper: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        replay_1_ch: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        dual_splitter: ['A', 'B', 'C', 'D', 'E', 'F'],
        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'],
        humidity_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        distance_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        rotatory: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        rain_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        pot: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        temp_dew: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        temp_gas: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        joystick: ['A', 'B', 'C', 'E', 'F'],
        // OLD ONEs
        temperature_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        sound_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        gas_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        bend_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        hall_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        OLED: ['D'],
        light_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],

        heartbeat_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        tact_switch: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],

        dip_switch: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        metal_detector: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        extender: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        humidity: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        linear_pot: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        gas: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],

        dual_switch: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        touch_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        pir_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        ultrasonic_sensor: ['A', 'C'],
        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3', 'E1', 'F1'],
        mini_geared_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3', 'E1', 'F1'],
        dc_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3', 'E1', 'F1'],
        dynamex_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3', 'E1', 'F1'],
        servo_motor: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'E1',
            'E2',
            'F1',
            'F2',
            'servo_motor1',
            'servo_motor2',
        ],
        servo_motor_270: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'E1',
            'E2',
            'F1',
            'F2',
            'servo_motor1',
            'servo_motor2',
        ],

        servo_motor_360: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'E1',
            'E2',
            'F1',
            'F2',
            'servo_motor1',
            'servo_motor2',
        ],
        stepper_motor: ['STPM'],
        tact_switch_2c: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            // 'D',
            // 'D1',
            // 'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
    },

    SHIELD0: {
        led: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'D1',
            'D2',
            'C1',
            'C2',
            // "E",
            // "E1",
            // "E2",
            'F',
            'F1',
            'F2',
        ],
        led_1c: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            // "E",
            // "E1","E2",
            'F',
            'F1',
            'F2',
        ],
        laser: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            // "E",
            // "E1","E2",
            'F',
            'F1',
            'F2',
        ],
        beeper: ['A', 'B', 'D', 'A1', 'A2', 'B1', 'B2', 'D1', 'D2'],
        replay_1_ch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        dual_splitter: ['A', 'B', 'D', 'C', 'F'],
        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'], //HI
        humidity_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        distance_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        rotatory: [
            'A',
            'B',
            'C',
            'A1', //  "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
        ],
        rain_sensor: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2', // "C2",
            'C1',
        ],
        pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_dew: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        joystick: ['A', 'B', 'C'],
        RGB: ['B', 'B1'],
        mp3: ['B'],
        OLED: ['D'],
        // OLD ONEs
        temperature_sensor: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        sound_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        gas_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        bend_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        hall_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        light_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1'],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        tact_switch: [
            'A',
            'B',
            'C',
            'F',
            'A1',
            'A2',
            'B1',
            'B2',
            'C1',
            'C2',
            'F1',
            'F2',
        ],
        // tact_switch_2c: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        dip_switch: [
            'A',
            'B',
            'C',
            'F',
            'A1',
            'A2',
            'B1',
            'B2',
            'C1',
            'C2',
            'F1',
            'F2',
        ],
        metal_detector: [
            'A',
            'B',
            'C',
            'F',
            'A1',
            'A2',
            'B1',
            'B2',
            'C1',
            'C2',
            'F1',
            'F2',
        ],
        extender: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        humidity: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        linear_pot: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        // temp: [
        //   "A",
        //   "B",
        //   "C",
        //   "A1",
        //   //  "A2",
        //   "B1",
        //   // "B2",
        //   // "C2",
        //   "C1",
        // ],
        gas: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],

        dual_switch: [
            'A',
            'B',
            'C',
            'F',
            'A1',
            'A2',
            'B1',
            'B2',
            'C1',
            'C2',
            'F1',
            'F2',
        ],
        touch_sensor: [
            'A',
            'B',
            'C',
            'F',
            'A1',
            'A2',
            'B1',
            'B2',
            'C1',
            'C2',
            'F1',
            'F2',
        ],
        pir_sensor: [
            'A',
            'B',
            'D',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        ultrasonic_sensor: ['A', 'C'],
        // '4_in_1_sensor': ['G'],
        // 'relay': ['MOTOR'],
        // 'dc_motor': ['MOTOR'],
        // 'electromagnet': ['MOTOR'],
        // 'geared_motor': ['MOTOR'],
        // 'mini_geared_motor': ['MOTOR'],

        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3', 'E1', 'F1'],
        mini_geared_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3', 'E1', 'F1'],
        dc_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3', 'E1', 'F1'],
        dynamex_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3', 'E1', 'F1'],
        servo_motor: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'E1',
            'E2',
            'F1',
            'F2',
            'servo_motor1',
            'servo_motor2',
        ],
        servo_motor_270: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'E1',
            'E2',
            'F1',
            'F2',
            'servo_motor1',
            'servo_motor2',
        ],
        servo_motor_360: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'E1',
            'E2',
            'F1',
            'F2',
            'servo_motor1',
            'servo_motor2',
        ],
        stepper_motor: ['STPM'],
    },
    ARMSHIELD1: {
        led: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'D1',
            'D2',
            'C1',
            'C2',
        ],
        led_1c: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            // "E",
            // "E1","E2",
        ],
        laser: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            // "E",
            // "E1","E2",
        ],
        beeper: ['A', 'B', 'D', 'A1', 'A2', 'B1', 'B2', 'D1', 'D2'],
        replay_1_ch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        dual_splitter: ['A', 'B', 'D', 'C'],

        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'], //HI
        humidity_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        distance_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            'B',
            'C',
            'A1',
            //  "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
        ],
        rain_sensor: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_dew: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        joystick: ['A', 'B', 'C'],
        RGB: ['B'],
        mp3: ['B'],
        OLED: ['D'],
        // OLD ONEs
        temperature_sensor: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        sound_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        gas_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        bend_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        hall_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        light_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1'],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        tact_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        // tact_switch_2c: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        dip_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        metal_detector: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        extender: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        humidity: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        linear_pot: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        // temp: [
        //   "A",
        //   "B",
        //   "C",
        //   "A1",
        //   //  "A2",
        //   "B1",
        //   // "B2",
        //   // "C2",
        //   "C1",
        // ],
        gas: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],

        dual_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        touch_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        pir_sensor: [
            'A',
            'B',
            'D',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        ultrasonic_sensor: ['A', 'C'],
        // '4_in_1_sensor': ['G'],
        // 'relay': ['MOTOR'],
        // 'dc_motor': ['MOTOR'],
        // 'electromagnet': ['MOTOR'],
        // 'geared_motor': ['MOTOR'],
        // 'mini_geared_motor': ['MOTOR'],

        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'MOTOR1'],
        mini_geared_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'MOTOR1'],
        dc_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'MOTOR1'],
        dynamex_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'MOTOR1'],
        speaker: ['SPK'],

        servo_motor: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'P0',
            'P1',
            'P2',
        ],
        servo_motor_270: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'P0',
            'P1',
            'P2',
        ],
        servo_motor_360: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'P0',
            'P1',
            'P2',
        ],
        serial_servo_motor: ['SS'],
        stepper_motor: ['STPM'],
    },
    ARMSHIELD0: {
        led: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'D1',
            'D2',
            'C1',
            'C2',
        ],
        led_1c: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            // "E",
            // "E1","E2",
        ],
        laser: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            // "E",
            // "E1","E2",
        ],
        beeper: ['A', 'B', 'D', 'A1', 'A2', 'B1', 'B2', 'D1', 'D2'],
        replay_1_ch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        dual_splitter: ['A', 'B', 'D', 'C'],

        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'], //HI
        humidity_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        distance_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            'B',
            'C',
            'A1',
            //  "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
        ],
        rain_sensor: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_dew: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        joystick: ['A', 'B', 'C'],
        RGB: ['B'],
        mp3: ['B'],
        OLED: ['D'],
        // OLD ONEs
        temperature_sensor: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        sound_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        gas_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        bend_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        hall_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        light_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1'],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        tact_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        // tact_switch_2c: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        dip_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        metal_detector: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        extender: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        humidity: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        linear_pot: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        // temp: [
        //   "A",
        //   "B",
        //   "C",
        //   "A1",
        //   //  "A2",
        //   "B1",
        //   // "B2",
        //   // "C2",
        //   "C1",
        // ],
        gas: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],

        dual_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        touch_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        pir_sensor: [
            'A',
            'B',
            'D',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        ultrasonic_sensor: ['A', 'C'],
        // '4_in_1_sensor': ['G'],
        // 'relay': ['MOTOR'],
        // 'dc_motor': ['MOTOR'],
        // 'electromagnet': ['MOTOR'],
        // 'geared_motor': ['MOTOR'],
        // 'mini_geared_motor': ['MOTOR'],

        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'MOTOR1'],
        mini_geared_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'MOTOR1'],
        dc_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'MOTOR1'],
        speaker: ['SPK'],

        servo_motor: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'P0',
            'P1',
            'P2',
        ],
        servo_motor_270: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'P0',
            'P1',
            'P2',
        ],
        servo_motor_360: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'P0',
            'P1',
            'P2',
        ],
        serial_servo_motor: ['SS'],
        stepper_motor: ['STPM'],
    },

    PEECEEATR1: {
        RGB: ['B', 'B1'],
        led: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        led_1c: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        laser: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        beeper: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        replay_1_ch: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        dual_splitter: ['A', 'B', 'C', 'D'],
        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'],
        humidity_sensor: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        distance_sensor: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        rotatory: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        rain_sensor: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        pot: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        temp_dew: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        temp_gas: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        joystick: ['A', 'B', 'C'],
        // OLD ONEs
        temperature_sensor: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        sound_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        gas_sensor: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        bend_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        hall_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        OLED: ['D'],
        light_sensor: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],

        heartbeat_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        tact_switch: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],

        dip_switch: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        metal_detector: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        extender: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        humidity: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        linear_pot: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        gas: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],

        dual_switch: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        touch_sensor: ['A', 'A1', 'A2', 'B', 'B1', 'B2', 'C', 'C1', 'C2'],
        pir_sensor: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
        ],
        ultrasonic_sensor: ['A', 'C'],
        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: [
            'A1',
            'C1',
            'B1',
            'D1',
            'M1',
            'M2',
            'M3',
            'M4',
            'G',
            'H',
        ],
        mini_geared_motor: [
            'A1',
            'C1',
            'B1',
            'D1',
            'M1',
            'M2',
            'M3',
            'M4',
            'G',
            'H',
        ],
        dc_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M2', 'M3', 'M4', 'G', 'H'],
        dynamex_motor: [
            'A1',
            'C1',
            'B1',
            'D1',
            'M1',
            'M2',
            'M3',
            'M4',
            'G',
            'H',
        ],
        servo_motor: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'S1',
            'S2',
            // 'servo_motor1',
            // 'servo_motor2',
        ],
        servo_motor_270: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'S1',
            'S2',
            // 'servo_motor1',
            // 'servo_motor2',
        ],
        servo_motor_360: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'S1',
            'S2',
        ],
        stepper_motor: ['STPM'],
        tact_switch_2c: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
    },
    PEECEEATR0: {
        led: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'D1',
            'D2',
            'C1',
            'C2',
        ],
        led_1c: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            // "E",
            // "E1","E2",
        ],
        laser: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            // "E",
            // "E1","E2",
        ],
        beeper: ['A', 'B', 'D', 'A1', 'A2', 'B1', 'B2', 'D1', 'D2'],
        replay_1_ch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        dual_splitter: ['A', 'B', 'D', 'C', 'F'],

        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'], //HI
        humidity_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        distance_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            'B',
            'C',
            'A1',
            //  "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
        ],
        rain_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],

        pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_dew: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        joystick: ['A', 'B', 'C'],
        RGB: ['B'],
        mp3: ['B'],
        OLED: ['D'],
        // OLD ONEs
        temperature_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        sound_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        gas_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        bend_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        hall_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        light_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        tact_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        // tact_switch_2c: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        dip_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        metal_detector: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        extender: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        humidity: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        linear_pot: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            // "C2",
            'C1',
        ],
        // temp: [
        //   "A",
        //   "B",
        //   "C",
        //   "A1",
        //   //  "A2",
        //   "B1",
        //   // "B2",
        //   // "C2",
        //   "C1",
        // ],
        gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],

        dual_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        touch_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        pir_sensor: [
            'A',
            'B',
            'D',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        ultrasonic_sensor: ['A', 'C'],
        // '4_in_1_sensor': ['G'],
        // 'relay': ['MOTOR'],
        // 'dc_motor': ['MOTOR'],
        // 'electromagnet': ['MOTOR'],
        // 'geared_motor': ['MOTOR'],
        // 'mini_geared_motor': ['MOTOR'],

        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: [
            'A1',
            'C1',
            'B1',
            'D1',
            'M1',
            'M2',
            'M3',
            'M4',
            'G',
            'H',
        ],
        mini_geared_motor: [
            'A1',
            'C1',
            'B1',
            'D1',
            'M1',
            'M2',
            'M3',
            'M4',
            'G',
            'H',
        ],
        dc_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M2', 'M3', 'M4', 'G', 'H'],
        speaker: ['SPK'],

        servo_motor: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'S1',
            'S2',
        ],
        servo_motor_270: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'S1',
            'S2',
        ],
        servo_motor_360: [
            'A1',
            'A2',
            'C1',
            'C2',
            'B2',
            'B1',
            'D2',
            'D1',
            'S1',
            'S2',
        ],
        serial_servo_motor: ['SS'],
        stepper_motor: ['STPM'],
    },

    ACE1: {
        led: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'D1',
            'D2',
            'C1',
            'C2',
            'F',
            'F1',
            'F2',
        ],
        led_1c: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            'F',
            'F1',
            'F2',
        ],
        laser: [
            'A',
            'A1',
            'A2',
            'B',
            'B1',
            'B2',
            'C',
            'C1',
            'C2',
            'D',
            'D1',
            'D2',
            'E',
            'E1',
            'E2',
            'F',
            'F1',
            'F2',
        ],
        beeper: ['A', 'B', 'D', 'A1', 'A2', 'B1', 'B2', 'D1', 'D2'],
        replay_1_ch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        dual_splitter: ['A', 'B', 'D', 'C'],

        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'],
        humidity_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        distance_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        rain_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_dew: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        temp_gas: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        /////// Dual Channel //////
        joystick: ['A', 'B', 'C'],
        RGB: ['B', 'B1'],
        mp3: ['B'],
        OLED: ['D'],
        // OLD ONEs
        temperature_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        sound_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        gas_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        bend_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        hall_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        light_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],

        heartbeat_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        tact_switch: [
            'A',
            'B',
            'C',
            //'D',
            'A1',
            'B1',
            'C1',
            //'D1',
            'A2',
            'B2',
            'C2',
            //'D1',
            //'D2',
        ],

        /////// dual channel sensor ////////
        dip_switch: [
            'A',
            'B',
            'C',
            //'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            //'D1',
            // 'D2',
        ],
        metal_detector: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        /////// dual channel sensor ////////
        extender: [
            'A',
            'B',
            'C',
            // 'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            // 'D1',
            // 'D2',
        ],
        humidity: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        linear_pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        ///// Dual channel sensor ////
        dual_switch: [
            'A',
            'B',
            'C',
            //'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            // 'D1',
            // 'D2',
        ],
        touch_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D2',
            'D1',
        ],
        pir_sensor: [
            'A',
            'B',
            'D',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        ultrasonic_sensor: ['A', 'C'],
        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: ['A1', 'C1', 'B1', 'D1'],
        mini_geared_motor: ['A1', 'C1', 'B1', 'D1'],
        dc_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3'],
        dynamex_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3'],
        servo_motor: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
        servo_motor_270: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
        servo_motor_360: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
        stepper_motor: ['STPM'],
        tact_switch_2c: [
            'A',
            'B',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            //'D',
            // 'D1',
            // 'D2',
        ],
    },
    ACE0: {
        led: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'D1',
            'D2',
            'C1',
            'C2',
            'F',
            'F1',
            'F2',
        ],
        led_1c: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
            'F',
            'F1',
            'F2',
        ],
        laser: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C1',
            'C2',
            'D1',
            'D2',
        ],
        beeper: ['A', 'B', 'D', 'A1', 'A2', 'B1', 'B2', 'D1', 'D2'],
        replay_1_ch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        dual_splitter: ['A', 'B', 'D', 'C', 'F'],

        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'], //HI
        humidity_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        distance_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            'B',
            'C',
            'A1',
            //  "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
        ],
        rain_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_dew: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        joystick: ['A', 'B', 'C'],
        RGB: ['B', 'B1'],
        mp3: ['B'],
        OLED: ['D'],
        // OLD ONEs
        temperature_sensor: [
            'A',
            'B',
            'C',
            'A1',
            // "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
        ],
        sound_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        gas_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        bend_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        hall_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        // light_sensor: ['A', 'B', 'C', 'A1', 'B1', 'C1'],
        light_sensor: [
            'A',
            'B',
            'C',
            // 'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C1',
            'C2',
            // 'D1',
            // 'D2',
        ],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        tact_switch: ['A', 'B', 'C', 'A1', 'B1', 'C1', 'A2', 'B2', 'C2'],
        // tact_switch_2c: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        dip_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        metal_detector: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        extender: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        humidity: [
            'A',
            'B',
            'C',
            'A1',
            //  "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
        ],
        linear_pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        // temp: [
        //   "A",
        //   "B",
        //   "C",
        //   "A1",
        //   //  "A2",
        //   "B1",
        //   // "B2",
        //   // "C2",
        //   "C1",
        // ],
        gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        dual_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        touch_sensor: ['A', 'B', 'C', 'A1', 'B1', 'C1', 'C2', 'A2', 'B2'],
        pir_sensor: [
            'A',
            'B',
            'D',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        ultrasonic_sensor: ['A', 'C'],
        // '4_in_1_sensor': ['G'],
        // 'relay': ['MOTOR'],
        // 'dc_motor': ['MOTOR'],
        // 'electromagnet': ['MOTOR'],
        // 'geared_motor': ['MOTOR'],
        // 'mini_geared_motor': ['MOTOR'],

        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: ['A1', 'C1', 'B1', 'D1'],
        mini_geared_motor: ['A1', 'C1', 'B1', 'D1'],
        dc_motor: ['A1', 'C1', 'B1', 'D1', 'M1', 'M3'],
        servo_motor: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
        servo_motor_270: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
        servo_motor_360: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
        stepper_motor: ['STPM'],
    },

    HUMANOID1: {
        // led: ['A', 'C', 'A1', 'C1'],
        led: [],
        led_1c: ['A', 'C', 'A1', 'C1'],
        laser: ['A', 'C', 'A1', 'C1'],

        dual_splitter: ['A', 'C'],

        pc_motor_driver: ['A', 'C'],

        octa_splitter: ['C'], //HI
        distance_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        rain_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        pot: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        temp_dew: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        temp_gas: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        joystick: ['A', 'C'],
        RGB: ['A1'],
        mp3: ['B'],
        OLED: ['D'],
        // OLD ONEs
        temperature_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        sound_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        gas_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        bend_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        hall_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        light_sensor: ['A', 'C', 'C1', 'C2', 'A1', 'A2'],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        tact_switch: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        tact_switch_2c: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        dip_switch: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        metal_detector: ['A', 'C', 'C1', 'C2', 'A1', 'A2'],
        extender: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        humidity: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        linear_pot: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],

        gas: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],

        dual_switch: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        touch_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        pir_sensor: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],

        ultrasonic_sensor: ['A', 'C'],
        servo_extender: ['B'],
        //PC MOTOR DRIVER
        servo_motor: ['A1', 'C1', 'C1'],
        servo_motor_270: ['A1', 'C1', 'C1'],
        servo_motor_360: ['A1', 'C1', , 'C1'],

        // geared_motor: ['A1', 'C1'],
        // mini_geared_motor: ['A1', 'C1'],
        // dc_motor: ['A1', 'C1'],
    },
    HUMANOID0: {
        led: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'D1',
            'D2',
            'C1',
            'C2',
        ],
        led_1c: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'C1',
            'B1',
            'D1',
            'A2',
            'C2',
            'B2',
            'D2',
        ],
        laser: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'B1',
            'D1',
            'C1',
            'A2',
            'B2',
            'C2',
            'D2',
        ],
        beeper: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'B1',
            'D1',
            'C1',
            'A2',
            'B2',
            'C2',
            'D2',
        ],
        replay_1_ch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        dual_splitter: ['A', 'B', 'D', 'C'],

        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'], //HI
        humidity_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        distance_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'B1',
            'D1',
            'C1',
            'A2',
            'B2',
            'C2',
            'D2',
        ],
        rain_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        pot: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        temp_dew: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        temp_gas: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        joystick: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        RGB: ['B'],
        mp3: ['B'],
        OLED: ['D'],
        // OLD ONEs
        temperature_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        sound_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        gas_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        bend_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        hall_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        light_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'B1',
            'D1',
            'C1',
            'A2',
            'B2',
            'C2',
            'D2',
        ],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        tact_switch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        // tact_switch_2c: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        dip_switch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        metal_detector: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'B1',
            'D1',
            'C1',
            'A2',
            'B2',
            'C2',
            'D2',
        ],
        extender: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        humidity: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        linear_pot: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        gas: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],

        dual_switch: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        touch_sensor: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        pir_sensor: [
            'A',
            'B',
            'D',
            'C',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        ultrasonic_sensor: ['A', 'D'],
        // '4_in_1_sensor': ['G'],
        // 'relay': ['MOTOR'],
        // 'dc_motor': ['MOTOR'],
        // 'electromagnet': ['MOTOR'],
        // 'geared_motor': ['MOTOR'],
        // 'mini_geared_motor': ['MOTOR'],

        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: ['A1', 'C1', 'B1', 'D1'],
        mini_geared_motor: ['A1', 'C1', 'B1', 'D1'],
        dc_motor: ['A1', 'C1', 'B1', 'D1'],
        servo_motor: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
        servo_motor_270: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
        servo_motor_360: ['A1', 'A2', 'C1', 'C2', 'B2', 'B1', 'D2', 'D1'],
    },
    HUMANOID2: {
        led: ['C', 'C1', 'C2'],
        led_1c: ['A', 'A1', 'C', 'C1', 'C2'],
        laser: ['A', 'C', 'A1', 'C1', 'C2'],

        dual_splitter: ['A', 'C'],

        pc_motor_driver: ['A', 'C'],

        octa_splitter: ['C'], //HI
        distance_sensor: ['C', 'A', 'A1', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            // 'B',
            'C',
            // 'D',
            'A1',
            //  "A2",
            // 'B1',
            // "B2",
            // "C2",
            'C1',
            // 'D1',
        ],
        rain_sensor: ['A', 'C', 'A1', 'C2', 'C1'],
        pot: ['A', 'C', 'A1', 'C2', 'C1'],
        temp_dew: [
            'A',
            // 'B',
            'C',
            // 'D',
            'A1',

            // 'B1',
            // 'B2',
            'C2',
            'C1',
            // 'D1',
            // 'D2',
        ],
        temp_gas: [
            'A',
            // 'B',
            'C',
            // 'D',
            'A1',
            // 'A2',
            // 'B1',
            // 'B2',
            'C2',
            'C1',
            // 'D1',
            // 'D2',
        ],
        joystick: ['C'],
        RGB: ['A1'],
        // mp3: ['B'],
        // OLED: ['D'],
        // OLD ONEs
        temperature_sensor: ['A', 'C', 'A1', 'C2', 'C1'],
        sound_sensor: ['A', 'C', 'A1', 'C2', 'C1'],
        gas_sensor: ['A', 'C', 'A1', 'C2', 'C1'],
        bend_sensor: ['A', 'C', 'A1', 'C2', 'C1'],
        hall_sensor: ['A', 'C', 'A1', 'C2', 'C1'],
        light_sensor: ['A', 'C', 'C1', 'C2', 'A1'],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: ['A', 'C', 'A1', 'C2', 'C1'],
        tact_switch: ['A', 'C', 'A1', 'C2', 'C1'],
        tact_switch_2c: ['C', 'C2', 'C1'],
        dip_switch: ['C', 'C2', 'C1'],
        metal_detector: ['A', 'C', 'C1', 'C2', 'A1'],
        extender: ['A', 'C', 'A1', 'C2', 'C1'],
        humidity: ['A', 'C', 'A1', 'C2', 'C1'],
        linear_pot: ['A', 'C', 'A1', 'C2', 'C1'],

        gas: ['A', 'C', 'A1', 'C2', 'C1'],

        dual_switch: ['C', 'C2', 'C1'],
        touch_sensor: ['A', 'C', 'A1', 'C2', 'C1'],
        pir_sensor: ['A', 'C', 'A1', 'C2', 'C1'],

        ultrasonic_sensor: ['C'],
        // servo_extender: ['B'],
        //PC MOTOR DRIVER
        servo_motor: ['A1', 'C1', 'C2'],
        servo_motor_270: ['A1', 'C1', 'C2'],
        servo_motor_360: ['A1', 'C1', 'C2'],

        geared_motor: ['C1'],
        mini_geared_motor: ['C1'],
        dc_motor: ['C1'],
    },
    HEXAPOD2: {
        led: ['B', 'C', 'C1', 'C2', 'B1', 'B2'],
        led_1c: ['A', 'B', 'C', 'A1', 'C1', 'C2', 'B1', 'B2'],
        laser: ['A', 'B', 'C', 'A1', 'C1', 'C2', 'B1', 'B2'],

        dual_splitter: ['A', 'B', 'C'],

        pc_motor_driver: ['D', 'A', 'C'],

        octa_splitter: ['C'], //HI
        distance_sensor: ['A', 'B', 'C', 'A1', 'B1', 'B2', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            //  "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
            'D1',
        ],
        rain_sensor: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        pot: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        temp_dew: [
            'A',
            'B',
            'C',
            'D',
            'A1',

            'B1',
            'B2',
            'C2',
            'C1',
            // 'D1',
            // 'D2',
        ],
        temp_gas: [
            'A',
            'B',
            'C',
            // 'D',
            'A1',

            'B1',
            'B2',
            'C2',
            'C1',
            // 'D1',
            // 'D2',
        ],
        joystick: ['B', 'C'],
        RGB: ['A1'],
        mp3: ['B'],
        OLED: ['D'],
        temperature_sensor: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        sound_sensor: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        gas_sensor: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        bend_sensor: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        hall_sensor: ['A', 'B', 'C', 'A1', , 'C2', 'C1', 'B1', 'B2'],
        light_sensor: ['A', 'B', 'C', 'C1', 'C2', 'A1', 'B1', 'B2'],
        heartbeat_sensor: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        tact_switch: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        tact_switch_2c: ['C', 'B', 'C2', 'C1', 'B1', 'B2'],
        dip_switch: ['B', 'C', 'C2', 'C1', 'B1', 'B2'],
        metal_detector: ['A', 'B', 'C', 'C1', 'C2', 'A1', 'B1', 'B2'],
        extender: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        humidity: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        linear_pot: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],

        gas: ['A', 'B', 'C', 'A1', 'B1', 'B2', 'C2', 'C1'],

        dual_switch: ['B', 'C', 'C2', 'C1', 'B1', 'B2'],
        touch_sensor: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],
        pir_sensor: ['A', 'B', 'C', 'A1', 'C2', 'C1', 'B1', 'B2'],

        ultrasonic_sensor: ['C'],
        servo_extender: [],
        //PC MOTOR DRIVER
        servo_motor: ['A1', 'C1', 'C2', 'B2', 'B1'],
        servo_motor_270: ['A1', 'C1', 'C2', 'B2', 'B1'],
        servo_motor_360: ['A1', 'C1', 'C2', 'B2', 'B1'],
        geared_motor: ['B1', 'C1'],
        mini_geared_motor: ['B1', 'C1'],
        dc_motor: ['B1', 'C1'],
    },
    HEXAPOD1: {
        led: ['B', 'B1', 'B2'],
        led_1c: ['A', 'B', 'C', 'A1', 'B1', 'B2', 'C1'],
        laser: ['A', 'B', 'C', 'A1', 'B1', 'B2', 'C1'],

        dual_splitter: ['A', 'B', 'C'],

        pc_motor_driver: ['D', 'B', 'A', 'C'],

        octa_splitter: ['C'], //HI
        distance_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        // NEW sensor COMPONENTS
        rotatory: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            //  "A2",
            'B1',
            // "B2",
            // "C2",
            'C1',
            'D1',
        ],
        rain_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        temp_dew: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        temp_gas: [
            'A',
            'B',
            'C',
            'D',
            'A1',
            'A2',
            'B1',
            'B2',
            'C2',
            'C1',
            'D1',
            'D2',
        ],
        joystick: ['A', 'B', 'C'],
        RGB: ['A1'],
        mp3: ['B'],
        OLED: [],
        // OLD ONEs
        temperature_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        sound_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        gas_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        bend_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        hall_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        light_sensor: ['A', 'B', 'C', 'C1', 'C2', 'A1', 'A2', 'B1', 'B2'],
        // rain_sensor: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        // metal_detector: ["A", "B", "C", "A1", "A2", "B1", "B2", "C2", "C1"],
        heartbeat_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        tact_switch_2c: ['A', 'C', 'B', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        tact_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        // tact_switch_2c: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        dip_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        metal_detector: ['A', 'B', 'C', 'C1', 'C2', 'A1', 'A2', 'B1', 'B2'],
        extender: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        humidity: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        linear_pot: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        gas: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        dual_switch: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        touch_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],
        pir_sensor: ['A', 'B', 'C', 'A1', 'A2', 'B1', 'B2', 'C2', 'C1'],

        ultrasonic_sensor: ['A', 'C'],
        servo_extender: ['B'],
        //PC MOTOR DRIVER
        servo_motor: ['A1', 'C1', 'B2', 'B1'],
        servo_motor_270: ['A1', 'C1', 'B2', 'B1'],
        servo_motor_360: ['A1', 'C1', 'B2', 'B1'],
        geared_motor: ['B1'],
        mini_geared_motor: ['B1'],
        dc_motor: ['B1'],
    },
    ROBOKI1: {
        led: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        led_1c: ['A', 'B', 'A1', 'B1', 'A2', 'B2'],
        laser: ['A', 'A1', 'A2', 'B', 'B1', 'B2'],
        beeper: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        replay_1_ch: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],

        dual_splitter: ['A', 'B'],

        pc_motor_driver: ['A', 'B'],

        octa_splitter: ['B'],
        humidity_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        distance_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],

        // NEW sensor COMPONENTS
        rotatory: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        rain_sensor: ['A', 'B', 'A1', 'A2', 'B2', 'B1'],
        pot: ['A', 'B', 'A1', 'A2', 'B2', 'B1'],
        temp_dew: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        temp_gas: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        /////// Dual Channel //////
        joystick: ['A', 'B'],
        RGB: ['A', 'A1'],
        mp3: ['B'],
        OLED: ['C'],
        // OLD ONEs
        temperature_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        sound_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        gas_sensor: ['A', 'B', 'A1', 'A2', 'B2', 'B1'],
        bend_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        hall_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        light_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],

        heartbeat_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        tact_switch: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        tact_switch_2c: ['A', 'C', 'A1', 'A2', 'C2', 'C1'],
        /////// dual channel sensor ////////
        dip_switch: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        metal_detector: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        /////// dual channel sensor ////////
        extender: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        humidity: ['A', 'B', 'A1', 'A2', 'B2', 'B1'],
        linear_pot: ['A', 'B', 'A1', 'A2', 'B2', 'B1'],

        gas: ['A', 'B', 'A1', 'A2', 'B2', 'B1'],

        ///// Dual channel sensor ////
        dual_switch: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        touch_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        pir_sensor: ['A', 'B', 'A2', 'B1', 'B2'],
        ultrasonic_sensor: ['A', 'B'],
        servo_extender: ['B'],

        //PC MOTOR DRIVER
        geared_motor: ['A1', 'B1', 'M1', 'M3'],
        mini_geared_motor: ['A1', 'B1', 'M1', 'M3'],
        dc_motor: ['A1', 'B1', 'M1', 'M3'],
        dynamex_motor: ['A1', 'B1', 'M1', 'M3'],
        servo_motor: ['A1', 'A2', 'B1', 'B2', 'servo_motor1', 'servo_motor2'],
        servo_motor_270: [
            'A1',
            'A2',
            'B1',
            'B2',
            'servo_motor1',
            'servo_motor2',
        ],
        servo_motor_360: [
            'A1',
            'A2',
            'B1',
            'B2',
            'servo_motor1',
            'servo_motor2',
        ],
        stepper_motor: ['STPM'],
        tact_switch_2c: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
    },
    KLAW1: {
        led: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        led_1c: ['A', 'B', 'A1', 'B1', 'A2', 'B2'],
        laser: ['A', 'B', 'A1', 'B1', 'A2', 'B2'],
        beeper: ['A', 'B', 'A1', 'B1', 'A2', 'B2'],
        dual_splitter: ['A', 'B'],
        pc_motor_driver: ['B', 'A'],
        humidity_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        distance_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        ultrasonic_sensor: ['A'],
        rotatory: ['A', 'B', 'A1', 'B1', 'A2', 'B2'],
        rain_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        pot: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        temp_dew: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        temp_gas: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        joystick: ['A', 'B'],
        temperature_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        sound_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        gas_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        bend_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        light_sensor: ['A', 'B', 'A1', 'B1', 'A2', 'B2'],
        tact_switch: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        dip_switch: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        metal_detector: ['A', 'B', 'A1', 'B1', 'A2', 'B2'],
        extender: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        humidity: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        linear_pot: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        gas: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        dual_switch: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        touch_sensor: ['A', 'B', 'A1', 'A2', 'B1', 'B2'],
        servo_extender: ['B'],
        geared_motor: ['A1', 'B1'], //'F1', 'F2'
        mini_geared_motor: ['A1', 'B1'], //'F1', 'F2'
        dc_motor: ['A1', 'B1'], //'F1', 'F2'
        servo_motor: ['A1', 'A2', 'B2', 'B1'],
        servo_motor_270: ['A1', 'A2', 'B2', 'B1'],
        serial_servo_motor: [],
        servo_motor_360: ['A1', 'A2', 'B2', 'B1'],
        PWM: ['PW1', 'PW2', 'PW3', 'PW4', 'PW5'],
    },

    TOKI1: {
        // led: [],
        // led_1c: [],
        // laser: ['A1', 'A2', 'B1', 'servo_motor1', 'servo_motor2'],
        LED_blue: [
            'A1',
            'A2',
            'B1',
            'C1',
            'C2',
            // 'servo_motor1',
            // 'servo_motor2',
        ],
        LED_green: [
            'A1',
            'A2',
            'B1',
            'C1',
            'C2',
            // 'servo_motor1',
            // 'servo_motor2',
        ],
        LED_red: ['A1', 'A2', 'B1', 'C1', 'C2'],
        LED_white: [
            'A1',
            'A2',
            'B1',
            'C1',
            'C2',
            // 'servo_motor1',
            // 'servo_motor2',
        ],
        laser_diode: [
            'A1',
            'A2',
            'B1',
            'C1',
            'C2',
            // 'servo_motor1',
            // 'servo_motor2',
        ],
        L293D_motor_driver: ['C1', 'C2'],

        geared_motor: ['C1', 'C2', 'M1', 'M3'],
        mini_geared_motor: ['C1', 'C2', 'M1', 'M3'],
        dc_motor: ['C1', 'C2', 'M1', 'M3'],
        servo_motor: ['servo_motor1', 'servo_motor2'],
        servo_motor_270: ['servo_motor1', 'servo_motor2'],
        servo_motor_360: ['servo_motor1', 'servo_motor2'],

        tact_switch: ['A1', 'A2', 'B1', 'servo_motor1', 'servo_motor2'],
        rain_sensor: ['A1', 'A2', 'B1'],
        sound_sensor: ['A1', 'A2', 'B1'],
        LDR_sensor: ['A1', 'A2', 'B1'],
        PIR_sensor: ['A1', 'A2', 'B1'],
        DHT11: ['A1', 'A2', 'B1'],
        capacitative_touch_sensor: ['A1', 'A2', 'B1'],
        IR_proximity: ['A1', 'A2', 'B1'],
        ultrasonic_sensor: ['A', 'B'],
    },
}
